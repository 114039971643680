import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import Lightbox from "react-image-lightbox";

import {
    getMobileImage,
    getTabletImage,
    getDesktopImage,
} from "../../../helpers/Contentful/images";

import { ILightboxFnProps } from "./types";

export default function LightboxFn(props: ILightboxFnProps) {
    const {
        cards,
        cardIndex,
        isOpen,
        displayTitle = true,
        displayCaption = true,
        setCardIndex,
        setIsOpen,
    } = props;

    const card = cards[cardIndex];

    function getResponsiveImage(img: string) {
        let image;
        if (isMobile) {
            image = getMobileImage(img);
        } else if (isTablet) {
            image = getTabletImage(img);
        } else {
            image = getDesktopImage(img);
        }
        return image;
    }

    function appendLightboxLink(link: string, title: string): void {
        if (document.getElementById("lightboxExploreLink")) {
            return;
        }
        const div = document.querySelector(".ril-caption-content");
        const a = document.createElement("a");
        a.setAttribute("href", link);
        a.setAttribute("id", "lightboxExploreLink");
        a.innerHTML = `Explore ${title}`;
        div.appendChild(a);
    }

    return (
        <>
            {isOpen && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={getResponsiveImage(card.image.file.url)}
                    nextSrc={
                        cards.length > 1
                            ? getResponsiveImage(
                                  cards[(cardIndex + 1) % cards.length].image
                                      .file.url
                              )
                            : ""
                    }
                    prevSrc={
                        cards.length > 1
                            ? getResponsiveImage(
                                  cards[
                                      (cardIndex + cards.length - 1) %
                                          cards.length
                                  ].image.file.url
                              )
                            : ""
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setCardIndex(
                            (cardIndex + cards.length - 1) % cards.length
                        )
                    }
                    onMoveNextRequest={() =>
                        setCardIndex((cardIndex + 1) % cards.length)
                    }
                    imageTitle={displayTitle && card.title}
                    imageCaption={displayCaption && card.shortDescription}
                    onImageLoad={() => {
                        if (
                            displayCaption &&
                            card.shortDescription &&
                            card.link &&
                            card.title
                        ) {
                            appendLightboxLink(card.link, card.title);
                        }
                    }}
                />
            )}
        </>
    );
}
